import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Test Week`}</em></p>
    <p>{`Max Unbroken Double Unders (3 attempts)`}</p>
    <p>{`Max Height Box Jump (no step in)`}</p>
    <p>{`Overhead Squat to a 1RM`}</p>
    <p>{`then,`}</p>
    <p>{`“Nancy”`}</p>
    <p>{`5 rounds for time of:`}</p>
    <p>{`400M Run`}</p>
    <p>{`15-OHS’s (95/65)`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      